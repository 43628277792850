export const About_Content = {
  landing: {
    headerTitle1: "TECHNICAL",
    headerTitle2: "EXPERIENCE",
    headerTitleBg1: "/TextBackground/technicalBg.svg",
    headerTitleBg2: "/TextBackground/Experience.svg",
    smallTitle1: {
      title: "Former Data",
      subTitle: "Scientist",
    },
    smallTitle2: {
      timeZone: "Europe/Moscow",
    },
  },
  skills_Articals: [
    {
      title: "Infrastructure as Code Using:",
      description: [
        "Terraform",
        "Ansible",
        "AWS CloudFormation",
      ],
    },
    {
      title: "Observability and Monitoring Using:",
      description: [
        "Datadog",
        "Prometheus",
        "Grafana",
        "AWS CloudWatch",
      ],
    },
    {
      title: "Continuous Integration and Continuous Delivery/Deployment Using:",
      description: [
        "Gitlab CI/CD",
        "Github Actions",
        "AWS CodePipeline",
        "Travis CI",
        "Jenkins",
      ],
    },
    {
      title: "Containerization and Container Orchestration Using:",
      description: [
        "Docker",
        "Podman",
        "Kubernetes",
        "Docker Compose",
        "Docker Swarm",
      ],
    },
    {
      title: "Programming Languages & Frameworks",
      description: [
        "Python",
        "GoLang",
        "Bash",
        "Flask",
      ],
    },

 
  ],
  volunteer_Articals: [
    {
      title: "Kamilimu",
      from: "Oct 2022",
      to: "March 2023",
      description:
        "As part of my responsibilities, I was in charge of managing the event space and maintaining crowd control during events. I also coordinated with various external vendors to ensure the smooth delivery of services. Additionally, I assisted with the logistics of public workshops.",
    },
    {
      title: "Public Speaking Judge",
      description:
        "At PAUDC 2022 I participated in the adjudication of preliminary, semi-final, and final rounds of public speaking and was selected as the fifth best judge out of 40 judges.",
    },
  ],
  workExperience_Articals: [
    {
      categoryTitle: "DevOps Engineer",
      title: "Aza Finance",
      from: "january 2022",
      to: "present",
      image: "/icons/devops_logo_lg.png",
      description: [
        "Integrated Datadog for real-time observability and monitoring, leading to a reduction in undetected system anomalies and ensuring a more proactive issue resolution strategy.",
        "Spearheaded the implementation of an Internal Developer Platform(IDP) that centralized and standardized the creation and maintenance of microservices, enabling software engineers to be self-reliant.",
        "Integrated Terratest into the development lifecycle, ensuring that Terraform modules are tested and validated before deployment, reducing infrastructure-related issues.",
        "Contributed to the team's successful consolidation of multiple CI/CD Pipelines into a single streamlined pipeline, leading to enhanced operational efficiency, a reduction in costs by 30%, and simplified management and monitoring processes",
        "Upheld best practice cloud security principles, achieving consistent alignment with ISO27001 certification standards and bolstering our organization's cybersecurity posture.",
        "Designed and implemented a comprehensive DevOps runbook, centralizing troubleshooting guidelines and best practices, which led to a reduction in incident resolution time and enhanced team efficiency.",
        "Led a cost optimization initiative for Datadog, successfully reducing the monitoring expenses by 50% through strategic adjustments in resource utilization and configuration.",
        "Maintained high system uptime by proactively monitoring and addressing infrastructure issues, resolving Severity 1 incidents promptly to ensure minimal downtime and business continuity.",
        "Designed and implemented automation scripts to eliminate redundant tasks, increasing operational efficiency and reducing manual workload",
      ],
    },
    {
      categoryTitle: "DevOps Engineer",
      title: "E-kraal Innovation Hub",
      from: "May 2020",
      to: "Dec 2021",
      image: "/icons/innovative_hub_icon.png",
      description: [
        "Migrated an application from a monolithic architecture to microservice architecture using Docker and Kubernetes which led to improved scalability, fault isolation, and fault tolerance.",
        "Designed, implemented, and maintained a CI/CD pipeline using Gitlab CI/CD tools leading to an increase of code deployments by 30%.",
        "Deployed and scaled an employee management system that serves multiple concurrent users.",
        "Implemented canary deployment from a red-blue deployment leading to better exibility of the business to test new features.",
        "Implemented an infrastructure monitoring and alerting system that led to reduced downtime by enabling faster response times.",
      ],
    },
    {
      categoryTitle: "Data Scientist",
      title: "E-kraal Innovation Hub",
      from: "Oct 2019",
      to: "May 2020",
      image: "/icons/innovative_hub_icon.png",
      description: [
        "Designed and implemented an English to Swahili language translation model that is being used by over 500 users.",
        "Designed, implemented, and maintained an ETL pipeline that processes text data from 6 different sources. The pipeline can process text data at an average rate of 50 messages per second.",
        "Built a dashboard using ELK stack to give an at-a-glance view of trending topics and sentiments from Twitter.",
        "Collaborated with the Business Analyst and Marketing Teams in identifying several unique user demographics based on their interactions.",
      ],
      downloadBtnLink: "https://linkedin.com/in/muokicaleb",
    },
  ],

};
